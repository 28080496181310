.Header {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.Header .link {
	font-weight: 700;
	font-size: 32px;
	letter-spacing: 3.62px;
	margin: 120px 0 0 0;
	text-decoration: none;
	color: #282828;
}

.Header .link span {
	font-size: 20px;
	font-weight: 500;
}

.Header h1 {
	font-weight: 600;
	font-size: 14px;
	letter-spacing: 4px;
	margin: 35px 0 0 0;
}




@media only screen and (max-width: 620px) {
	.Header .link {
		font-weight: 700;
		font-size: 19px;
		letter-spacing: 1px;
		margin: 50px 0 0 0;
	}
	
	.Header .link span {
		font-size: 13px;
		font-weight: 500;
	}

	.Header h1 {
		font-weight: 500;
		font-size: 12px;
		letter-spacing: 1px;
		margin: 10px 0 0 0;
	}
}