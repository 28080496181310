.Footer {
	position: absolute;
	width: 100vw;	
	display: flex;
	justify-content: center;
	margin-bottom: 37px;
	bottom: 0;
	z-index: 10;
}


/* Footer */
footer {
	margin: 127px 0 42px 0;
	display: flex;
	align-items: center;
	flex-direction: column;
}

footer .footer-links {
	margin-top: 30px;
	display: flex;
	align-items: center;
}

footer .links {
	font-size: 11px;
	letter-spacing: 3px;
	font-weight: 600;
	margin-left: 39px;
	text-decoration: none;
	color: #6B6B6B;
}

footer :first-child {
	margin-left: 0;
}




.Footer .sozial-container {
	width: 142px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}	

.Footer .sozial-container button {
	width: 24px;
	height: 20px;
	background: rgba(0, 0, 0, 0);
}

.Footer .sozial-container img {
	max-width: 24px;
	max-height: 20px;
}





@media only screen and (max-width: 620px) {
	/* Footer */
	footer {
		margin: 127px 0 10px 0;
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	footer .links {
		font-size: 11px;
		margin-left: 15px;
		letter-spacing: 0.3px;
	}
	
	footer :first-child {
		margin-left: 0;
	}
}