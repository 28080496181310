.Home {
	min-height: 100vh;
	position: relative;
	padding-bottom: 350px;
}


/* --- DEEP SALE --- */
.Home .app-container {
	margin-top: 200px;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 100vw;
}

.Home .iphone-image {
	min-width: 472px;
	width: 472px;
	height: 584px;
	background-size: contain;
    background-repeat: no-repeat;
}

.Home  #image-widgetwatch {
	background-image: url(../ASSETS/WIDGETWATCH-2x.jpg);
}

.Home  #image-deepsale {
	background-image: url(../ASSETS/DeepSale-2x.jpg);
}

.Home #image-where {
	background-image: url(../ASSETS/Where-2x.jpg);
}

.Home  #image-inStockTrain {
	background-image: url(../ASSETS/INSTOCKTRAIN-2x.jpg);
}

.Home  #image-namir {
	background-image: url(../ASSETS/NAMIR-2x.jpg);
}

.Home  #image-aora {
	background-image: url(../ASSETS/AORA-2x.jpg);
}

.Home .app-info {
	padding: 17px 121px 104px 13px;
	width: 396px;
}

.Home h3 {
	letter-spacing: 1px;
	font-weight: 700;
	font-size: 25px;
	text-align: left;
}

.Home h3 span {
	font-weight: 400;
}

#widgetwatch-title {
	letter-spacing: 3px;
	font-weight: 500;
	font-size: 20px;
}

.app-container .info {
	font-weight: 600;
	letter-spacing: 0.5;
	font-size: 13px;
	text-align: left;
}

#widgetwatch-info {
	font-weight: 600;
	opacity: 0.8;
}

.app-container .visit {
	display: block;
	background-color: #141414;
	color: white;
	width: 176px;
	height: 40px;
	min-height: 40px;
	border-radius: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 65px;
	text-decoration: none;
}

.app-container .visit .v {
	font-weight: 700;
	color: white;
	font-size: 15px;
}

.app-container .visit .brand {
	font-weight: 400;
	color: white;
	font-size: 12px;
	margin-left: 13px;
}

.app-container .visit .brand span {
	font-weight: 700;
}

.app-container .app-store {
	display: block;
	background-image: url(../ASSETS/App-Store-Badge.png);
	background-size: contain;
	background-repeat: no-repeat;
	width: 176px;
	height: 50px;
	margin-top: 20px;
}

.app-container #h3-app-2 {
	letter-spacing: 0px;
}

.app-container #as-app-2 {
	margin-top: 65px;
}

#widgetwatch-info-bandge {
	margin-top: 65px;
}






/* Large 2x */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx), (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){
	
}

/* Medium */
@media only screen and (max-width: 970px) {
	.Home .app-info {
		padding: 17px 121px 104px 0px;
	}
}

/* Medium 2x */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-width: 970px), only screen and (max-width: 970px) and (min-resolution: 1.5dppx), only screen and (max-width: 970px) and (min-resolution: 144dpi) {
	
}

/* Small */
@media only screen and (max-width: 815px) {
	.Home {
		padding-bottom: 170px;
	}

	.Home .app-container {
		margin-top: 60px;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.Home .app-info {
		width: calc(100vw - 40px);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: -63px;
		padding-right: 0;
	}

	.Home #app-container-2 {
		margin-top: 70px;
	}

	.Home .info {
		text-align: center;
		/* padding: 0 20px; */
	}

	.Home h3 {
		text-align: center;
		/* padding: 0 20px; */
	}
}

/* Small 2x */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5) and (max-width: 815px), only screen and (max-width: 815px) and (min-resolution: 1.5dppx), only screen and (max-width: 815px) and (min-resolution: 144dpi) {
	
}


@media only screen and (max-width: 472px) {
	.Home .iphone-image {
		min-width: 0;
		width: 100vw;
		height: 0;
		padding-top: 155.73333%; /* (img-height / img-width * container-width) */
	}

	.Home  #image-widgetwatch {
		background-image: url(../ASSETS/WIDGETWATCH-small.jpg);
	}

	.Home  #image-deepsale {
		background-image: url(../ASSETS/DeepSale-small.jpg);
	}
	
	.Home #image-where {
		background-image: url(../ASSETS/Where-small.jpg);
	}

	.Home  #image-inStockTrain {
		background-image: url(../ASSETS/INSTOCKTRAIN-small.jpg);
	}
	
	.Home  #image-namir {
		background-image: url(../ASSETS/NAMIR-small.jpg);
	}

	.Home  #image-aora {
		background-image: url(../ASSETS/AORA-small.jpg);
	}
}
