.Impressum {
	position: relative;
	min-height: 100vh;
}

.Impressum-wrapper {
	display: flex;
	justify-content: center;
}

.Impressum-wrapper .wrapper {
	width: 60vw;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	margin-top: 140px;
	padding-bottom: 340px;
}

.Impressum-wrapper .header {
	font-size: 20px;
	font-weight: 800;
	letter-spacing: 2.2px;
	margin-bottom: 61px;
}

.Impressum-wrapper .infos-wrapper {
	display: flex;
}

.Impressum-wrapper .infos-wrapper .seconde_container {
	margin-left: 292px;
}

.Impressum-wrapper .title {
	font-weight: 700;
	font-size: 14px;
	letter-spacing: 1.27px;
	text-align: left;
	margin-bottom: 11px;
	margin-top: 23px;
}	

.Impressum-wrapper .content {
	font-weight: 500;
	font-size: 12px;
	letter-spacing: 1px;
	text-align: left;
	margin-top: 3px;
}	


.Impressum-wrapper .infos-wrapper .content-m {
	font-weight: 500;
	font-size: 12px;
	letter-spacing: 1px;
	text-align: left;
	margin-top: 24px;
}	

.Impressum-wrapper .content-recht {
	font-weight: 500;
	font-size: 12px;
	letter-spacing: 1px;
	text-align: left;
	margin-top: 24px;
}	

.Impressum-wrapper .block-container {
	margin-top: 49px;
}



/* ___________ TABLET ___________ */
@media only screen and (max-width: 1100px) {
	.Impressum-wrapper {
		min-height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	.Impressum-wrapper .wrapper {
		margin-top: 0px;
		min-height: 100vh;
		width: 100vw;
		padding: 120px 40px 180px 40px;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		flex-direction: column;
	}

	.Impressum-wrapper .infos-wrapper .seconde_container {
		margin-left: 30px;
	}

	.Impressum-wrapper .content {
		letter-spacing: 0px;
	}	
}


@media only screen and (max-width: 750px) {
	.Impressum-wrapper .wrapper {
		padding: 60px 20px 220px 20px;
	}


	.Impressum-wrapper .infos-wrapper {
		flex-direction: column;
	}

	.Impressum-wrapper .infos-wrapper .seconde_container {
		margin-left: 0px;
	}

	.Impressum-wrapper .header {
		margin-bottom: 31px;
	}

	.Impressum-wrapper a {
		color: #3FA1EC;
		/* These are technically the same, but use both */
		overflow-wrap: break-word;
		word-wrap: break-word;
	
		-ms-word-break: break-all;
		/* Instead use this non-standard one: */
		word-break: break-word;
	
		/* Adds a hyphen where the word breaks, if supported (No Blink) */
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
	}

	.Impressum-wrapper .content {
		line-height: 17px;
		font-size: 13px;
	}	
}