.Contact {
	/* min-height: 100vh; */
}

.Contact .content {
	display: flex;
	justify-content: center;
}

.Contact .content a {
	font-size: 12px;
	letter-spacing: 2px;
	margin-top: calc(50vh - 143px);
	font-weight: 700;
	color: #282828;
	text-decoration: none;
}